import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
	<Layout>
		<h1>404!</h1>
	</Layout>
);

export default NotFoundPage;
